import { combineReducers } from 'redux';
import { NO_MATCH, CLEAR_FLIPPED } from '../actionTypes/cards';
import {
  START,
  RESET,
  PAUSE,
  RESUME,
  WIN,
  TOGGLE_SOUND,
  TOGGLE_DARK_MODE,
} from '../actionTypes/game';
import { UPDATE_INITIALS_SUCCESS } from '../actionTypes/leaderboard';

const won = (state = false, { type }) => {
  switch (type) {
    case WIN:
      return true;
    case RESET:
      return false;
    default:
      return state;
  }
};

const playing = (state = false, { type }) => {
  switch (type) {
    case START:
      return true;
    case RESET:
    case WIN:
      return false;
    default:
      return state;
  }
};

const allowFlips = (state = true, { type }) => {
  switch (type) {
    case NO_MATCH:
    case PAUSE:
      return false;
    case CLEAR_FLIPPED:
    case RESUME:
    case RESET:
      return true;
    default: return state;
  }
};

const cardIdOrder = (state = [], { type, payload }) => {
  switch (type) {
    case RESET:
      return payload;
    default:
      return state;
  }
};

const paused = (state = true, { type }) => {
  switch (type) {
    case PAUSE:
      return true;
    case RESUME:
    case RESET:
      return false;
    default:
      return state;
  }
};

const initials = (state = null, { type, payload }) => {
  switch (type) {
    case UPDATE_INITIALS_SUCCESS:
      return payload.initials;
    default:
      return state;
  }
};

const soundEffects = (state = true, { type }) => {
  switch (type) {
    case TOGGLE_SOUND:
      return !state;
    default:
      return state;
  }
};

const darkMode = (state = false, { type }) => {
  switch (type) {
    case TOGGLE_DARK_MODE:
      return !state;
    default:
      return state;
  }
};

export default combineReducers({
  won,
  cardIdOrder,
  playing,
  allowFlips,
  paused,
  initials,
  soundEffects,
  darkMode,
});
