import { combineReducers } from 'redux';
import game from './game';
import levels from './levels';
import cards from './cards';
import timer from './timer';
import leaderboard from './leaderboard';

const rootReducer = combineReducers({
  game,
  levels,
  cards,
  timer,
  leaderboard,
});

export default rootReducer;
