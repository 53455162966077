import React from 'react';
import PropTypes from 'prop-types';
import StyledTime from './styled/WinningTime';
import { getElapsedTime, formatTime } from './Timer/Timer';

const WinningTime = ({ baseTime, startedAt, stoppedAt }) => (
  <StyledTime>
    <span>Your time</span> {formatTime(getElapsedTime(baseTime, startedAt, stoppedAt) / 1000)}
  </StyledTime>
);

WinningTime.propTypes = {
  baseTime: PropTypes.number.isRequired,
  startedAt: PropTypes.number.isRequired,
  stoppedAt: PropTypes.number.isRequired,
};

export default WinningTime;
