import db from '../database';

export const addWin = (win) => {
  const { elapsedTime, initials, level } = win;
  return db.wins.add({
    elapsedTime,
    initials,
    level,
    createdOn: new Date().toJSON(),
  });
};

// from http://dexie.org/docs/Collection/Collection.offset()#paged-or-queries
export const loadTopTimes = async (level, count) => {
  const table = db.wins;
  const winsByLevel = table.where({ level });
  const primaryKeySet = new Set(await winsByLevel.primaryKeys());
  const pageKeys = [];

  await table
    .orderBy('elapsedTime')
    .until(() => pageKeys.length === count)
    .eachPrimaryKey((id) => {
      if (primaryKeySet.has(id)) {
        pageKeys.push(id);
      }
    });
  return Promise.all(pageKeys.map(id => table.get(id)));
};

export const updateInitials = (id, initials) => (
  db.wins.update(id, { initials })
);
