import * as types from '../actionTypes/leaderboard';
import { loadTopTimes as dbLoad, addWin as dbAdd, updateInitials as dbUpdate } from '../../db/leaderboard';

// LOAD
const loadLeaderboardRequest = (level, count) => ({
  type: types.LOAD_LEADERBOARD_REQUEST,
  payload: { level, count },
});

const loadLeaderboardSuccess = wins => ({
  type: types.LOAD_LEADERBOARD_SUCCESS,
  payload: wins,
});

const loadLeaderboardFailure = (level, count, error) => ({
  type: types.LOAD_LEADERBOARD_FAILURE,
  error: true,
  payload: new Error('Leaderboard could not be loaded'),
  meta: { level, count, error },
});

export const loadLeaderboard = (level, count) => async (dispatch) => {
  dispatch(loadLeaderboardRequest(level, count));
  try {
    const wins = await dbLoad(level, count);
    return dispatch(loadLeaderboardSuccess(wins));
  } catch (err) {
    return dispatch(loadLeaderboardFailure(level, count, err.message));
  }
};

// ADD
const addWinRequest = win => ({
  type: types.ADD_WIN_REQUEST,
  payload: win,
});

const addWinSuccess = win => ({
  type: types.ADD_WIN_SUCCESS,
  payload: win,
});

const addWinFailure = (win, error) => ({
  type: types.ADD_WIN_FAILURE,
  error: true,
  payload: new Error('Win could not be added'),
  meta: { win, error },
});

export const addWin = win => async (dispatch) => {
  dispatch(addWinRequest(win));
  try {
    const id = await dbAdd(win);
    return dispatch(addWinSuccess({ ...win, id }));
  } catch (err) {
    return dispatch(addWinFailure(win, err.message));
  }
};

// UPDATE INITIALS
const updateInitialsRequest = (id, initials) => ({
  type: types.UPDATE_INITIALS_REQUEST,
  payload: { id, initials },
});

const updateInitialsSuccess = (id, initials) => ({
  type: types.UPDATE_INITIALS_SUCCESS,
  payload: { id, initials },
});

const updateInitialsFailure = (id, initials, error) => ({
  type: types.UPDATE_INITIALS_FAILURE,
  error: true,
  payload: new Error('Win could not be updated'),
  meta: { id, initials, error },
});

export const updateInitials = (id, initials) => async (dispatch) => {
  dispatch(updateInitialsRequest(id, initials));
  try {
    await dbUpdate(id, initials);
    return dispatch(updateInitialsSuccess(id, initials));
  } catch (err) {
    return dispatch(updateInitialsFailure(id, initials, err));
  }
};
