import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import GameContainer from './GameContainer';
import SettingsContainer from './SettingsContainer';
import { loadLevels as loadLevelsAction, setDifficulty as setDifficultyAction } from '../redux/actions/levels';
import { loadLeaderboard as loadLeaderboardAction } from '../redux/actions/leaderboard';
import { pauseGame as pauseGameAction } from '../redux/actions/game';
import HeaderContainer from './HeaderContainer';
import { PlayingArea } from '../components/styled/App';
import App from '../components/App';

const AppContainer = ({
  paused,
  won,
  soundEffects,
  darkMode,
  difficulty,
  loadLevels,
  loadLeaderboard,
  setDifficulty,
}) => (
  <App
    loadLevels={loadLevels}
    setDifficulty={setDifficulty}
    loadLeaderboard={loadLeaderboard}
    difficulty={difficulty}
    soundEffects={soundEffects}
    darkMode={darkMode}
  >
    <HeaderContainer />
    <PlayingArea id="main">
      <GameContainer />
      {(paused || won) && (
        <SettingsContainer />
      )}
    </PlayingArea>
  </App>
);

AppContainer.propTypes = {
  loadLevels: PropTypes.func.isRequired,
  setDifficulty: PropTypes.func.isRequired,
  loadLeaderboard: PropTypes.func.isRequired,
  difficulty: PropTypes.string.isRequired,
  won: PropTypes.bool.isRequired,
  paused: PropTypes.bool.isRequired,
  soundEffects: PropTypes.bool.isRequired,
  darkMode: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  difficulty: state.levels.selectedDifficulty,
  playing: state.game.playing,
  won: state.game.won,
  paused: state.game.paused,
  soundEffects: state.game.soundEffects,
  darkMode: state.game.darkMode,
});

export default connect(
  mapStateToProps,
  {
    loadLevels: loadLevelsAction,
    setDifficulty: setDifficultyAction,
    pauseGame: pauseGameAction,
    loadLeaderboard: loadLeaderboardAction,
  },
)(AppContainer);
