import * as types from '../actionTypes/timer';

export const startTimer = basetime => ({
  type: types.START,
  payload: { basetime, now: new Date().getTime() },
});

export const stopTimer = now => ({
  type: types.STOP,
  payload: now,
});

export const resetTimer = () => ({
  type: types.RESET,
});
