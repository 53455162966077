import React from 'react';
import PropTypes from 'prop-types';
import GameBoard from './styled/GameBoard';
import Card from './Card';

const Game = ({
  won,
  playing,
  paused,
  deck,
  cardIds,
  flippedCardIds,
  matchedCardIds,
  cardDesign,
  darkMode,
  flipCard,
}) => {
  const shouldCardBeFlipped = (id) => {
    const isFlipped = flippedCardIds.indexOf(id) >= 0;
    const isMatched = matchedCardIds.indexOf(id) >= 0;
    return won || (playing && (isFlipped || isMatched));
  };

  return (
    <GameBoard won={won} paused={paused}>
      {cardIds.map(id => deck[id] && (
        <Card
          key={id}
          card={deck[id]}
          design={cardDesign}
          darkMode={darkMode}
          flipped={shouldCardBeFlipped(id)}
          onFlip={() => flipCard(id)}
        />
      ))}
    </GameBoard>
  );
};

Game.propTypes = {
  won: PropTypes.bool.isRequired,
  playing: PropTypes.bool.isRequired,
  paused: PropTypes.bool.isRequired,
  darkMode: PropTypes.bool.isRequired,
  deck: PropTypes.shape({}).isRequired,
  cardIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  flippedCardIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  matchedCardIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  cardDesign: PropTypes.string.isRequired,
  flipCard: PropTypes.func.isRequired,
};

export default Game;
