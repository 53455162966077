import React from 'react';
import PropTypes from 'prop-types';
import StyledCard from './styled/StyledCard';

const Card = ({
  card,
  onFlip,
  flipped,
  design,
  darkMode,
}) => (
  <StyledCard
    onClick={onFlip}
    onKeyPress={onFlip}
    flipped={flipped}
    className={design}
    darkMode={darkMode}
    role="button"
    tabIndex="0"
  >
    <span aria-hidden={!flipped}>{ card.card }</span>
  </StyledCard>
);

Card.propTypes = {
  card: PropTypes.shape({
    card: PropTypes.string.isRequired,
  }).isRequired,
  onFlip: PropTypes.func.isRequired,
  flipped: PropTypes.bool.isRequired,
  darkMode: PropTypes.bool.isRequired,
  design: PropTypes.string.isRequired,
};

export default Card;
