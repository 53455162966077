import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateInitials as updateInitialsAction } from '../redux/actions/leaderboard';
import { getSortedWins } from '../redux/reducers/leaderboard';
import HighScoreForm from '../components/HighScoreForm';
import Leaderboard from '../components/Leaderboard';
import WinningTime from '../components/WinningTime';
import OptionSectionContainer from '../components/styled/OptionSectionContainer';

const LeaderboardContainer = ({
  timer,
  leaderboard,
  canAddInitials,
  lastWinId,
  updateInitials,
  storedInitials,
  darkMode,
}) => (
  <OptionSectionContainer noborder>
    <WinningTime
      baseTime={timer.baseTime}
      startedAt={timer.startedAt}
      stoppedAt={timer.stoppedAt}
    />
    {canAddInitials && (
      <HighScoreForm id={lastWinId} saveInitials={updateInitials} initials={storedInitials} />
    )}
    {!canAddInitials && leaderboard.length > 0 && (
      <Leaderboard leaderboard={leaderboard} lastWinId={lastWinId} darkMode={darkMode} />
    )}
  </OptionSectionContainer>
);

LeaderboardContainer.propTypes = {
  timer: PropTypes.shape({
    baseTime: PropTypes.number.isRequired,
    startedAt: PropTypes.number.isRequired,
    stoppedAt: PropTypes.number.isRequired,
  }).isRequired,
  leaderboard: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    elapsedTime: PropTypes.number.isRequired,
    initials: PropTypes.string,
  })).isRequired,
  lastWinId: PropTypes.number,
  darkMode: PropTypes.bool.isRequired,
  canAddInitials: PropTypes.bool.isRequired,
  storedInitials: PropTypes.string.isRequired,
  updateInitials: PropTypes.func.isRequired,
};

LeaderboardContainer.defaultProps = {
  lastWinId: null,
};

const mapStateToProps = state => ({
  difficulty: state.levels.selectedDifficulty,
  timer: state.timer,
  leaderboard: getSortedWins(state.leaderboard),
  canAddInitials: state.leaderboard.canAddInitials,
  lastWinId: state.leaderboard.lastWinId,
  storedInitials: state.game.initials,
  darkMode: state.game.darkMode,
});

export default connect(
  mapStateToProps,
  { updateInitials: updateInitialsAction },
)(LeaderboardContainer);
