import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { resumeGame as resumeGameAction } from '../redux/actions/game';
import LevelOptionsContainer from './LevelOptionsContainer';
import CardBackOptionsContainer from './CardBackOptionsContainer';
import LeaderboardContainer from './LeaderboardContainer';
import { getElapsedTime } from '../components/Timer/Timer';
import SoundEffectsContainer from './SoundEffectsContainer';
import DarkModeContainer from './DarkModeContainer';
import OptionSectionContainer from '../components/styled/OptionSectionContainer';
import { StyledSettingsContainer, ToggleContainer, ContinueGameButton } from '../components/styled/Settings';

const SettingsContainer = ({
  playing,
  won,
  darkMode,
  timer,
  resumeGame,
}) => (
  <StyledSettingsContainer darkMode={darkMode}>
    {won && <LeaderboardContainer />}
    {playing && (
      <OptionSectionContainer>
        <ContinueGameButton
          type="button"
          onClick={() => {
            const { baseTime, startedAt, stoppedAt } = timer;
            resumeGame(getElapsedTime(baseTime, startedAt, stoppedAt));
          }}
        >
          Continue game
        </ContinueGameButton>
      </OptionSectionContainer>
    )}
    <OptionSectionContainer>
      <CardBackOptionsContainer />
      <ToggleContainer>
        <SoundEffectsContainer />
        <DarkModeContainer />
      </ToggleContainer>
    </OptionSectionContainer>
    <LevelOptionsContainer />
  </StyledSettingsContainer>
);

SettingsContainer.propTypes = {
  playing: PropTypes.bool.isRequired,
  won: PropTypes.bool.isRequired,
  darkMode: PropTypes.bool.isRequired,
  timer: PropTypes.shape({
    baseTime: PropTypes.number,
    startedAt: PropTypes.number,
    stoppedAt: PropTypes.number,
  }).isRequired,
  resumeGame: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  playing: state.game.playing,
  won: state.game.won,
  darkMode: state.game.darkMode,
  timer: state.timer,
});

export default connect(
  mapStateToProps,
  { resumeGame: resumeGameAction },
)(SettingsContainer);
