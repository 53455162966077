import React from 'react';
import PropTypes from 'prop-types';
import { formatTime } from './Timer/Timer';
import {
  StyledLeaderboard,
  LeaderHeader,
  LeaderTable,
  LeaderTableRow,
} from './styled/Leaderboard';

const Leaderboard = ({ leaderboard, lastWinId, darkMode }) => (
  <StyledLeaderboard darkMode={darkMode}>
    <LeaderHeader>Best Times</LeaderHeader>
    <LeaderTable>
      <thead>
        <tr>
          <th>Rank</th>
          <th>Time</th>
          <th>Name</th>
        </tr>
      </thead>
      <tbody>
        {leaderboard.map(({ id, elapsedTime, initials }, index) => (
          <LeaderTableRow key={id} highlight={lastWinId === id} darkMode={darkMode}>
            <td>{index + 1}.</td>
            <td>{formatTime(elapsedTime / 1000)}</td>
            <td>{initials}</td>
          </LeaderTableRow>
        ))}
      </tbody>
    </LeaderTable>
  </StyledLeaderboard>
);

Leaderboard.propTypes = {
  leaderboard: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    elapsedTime: PropTypes.number.isRequired,
    initials: PropTypes.string,
  })).isRequired,
  lastWinId: PropTypes.number,
  darkMode: PropTypes.bool.isRequired,
};

Leaderboard.defaultProps = {
  lastWinId: null,
};

export default Leaderboard;
