import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import soundsMiddleware from 'redux-sounds';
import { loadState } from './persist';
import reducer from './reducers/index';
import flipCardAudio from '../sounds/flip-card.mp3';
import matchAudio from '../sounds/match.mp3';
import wonAudio from '../sounds/won.mp3';

const soundsData = {
  flips: {
    src: [flipCardAudio],
    sprite: {
      flipCard: [500, 1000],
    },
  },
  match: matchAudio,
  won: wonAudio,
};
const loadedSoundsMiddleware = soundsMiddleware(soundsData);
const middleware = [thunk, loadedSoundsMiddleware];
const persistedState = loadState();

// https://github.com/zalmoxisus/redux-devtools-extension
// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default createStore(
  reducer,
  persistedState,
  composeEnhancers(applyMiddleware(...middleware)),
);
