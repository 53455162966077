import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { knuthShuffle } from 'knuth-shuffle';
import { setDifficulty as setDifficultyAction } from '../redux/actions/levels';
import { resetGame as resetGameAction } from '../redux/actions/game';
import LevelOption from '../components/LevelOption';
import LevelOptionsList from '../components/LevelOptionsList';
import { LEADERBOARD_COUNT } from '../redux/reducers/leaderboard';
import { loadLeaderboard as loadLeaderboardAction } from '../redux/actions/leaderboard';

const LevelOptionsContainer = ({
  setDifficulty,
  resetGame,
  loadLeaderboard,
  levels,
}) => (
  <LevelOptionsList title="Start a new game">
    {Object.keys(levels).map(key => (
      <LevelOption
        key={key}
        option={key}
        onSetDifficulty={() => {
          setDifficulty(key);
          loadLeaderboard(key, LEADERBOARD_COUNT);
          resetGame(knuthShuffle(levels[key].allCardIds));
        }}
      />
    ))}
  </LevelOptionsList>
);

LevelOptionsContainer.propTypes = {
  setDifficulty: PropTypes.func.isRequired,
  resetGame: PropTypes.func.isRequired,
  loadLeaderboard: PropTypes.func.isRequired,
  levels: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => ({
  levels: state.levels.byDifficulty,
});

export default connect(
  mapStateToProps,
  {
    setDifficulty: setDifficultyAction,
    resetGame: resetGameAction,
    loadLeaderboard: loadLeaderboardAction,
  },
)(LevelOptionsContainer);
