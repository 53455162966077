import React from 'react';
import PropTypes from 'prop-types';
import { SettingsTitle } from './styled/Settings';
import { CardBackOptionsListContainer, OptionsContainer } from './styled/CardBackOption';

const CardBackOptionsList = ({ title, children }) => (
  <CardBackOptionsListContainer>
    <SettingsTitle>{title}</SettingsTitle>
    <OptionsContainer>{children}</OptionsContainer>
  </CardBackOptionsListContainer>
);

CardBackOptionsList.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default CardBackOptionsList;
