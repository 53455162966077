import { LOAD_LEADERBOARD_SUCCESS, ADD_WIN_SUCCESS, UPDATE_INITIALS_SUCCESS } from '../actionTypes/leaderboard';

export const LEADERBOARD_COUNT = 5;

const byId = (state = {}, { type, payload }) => {
  switch (type) {
    case LOAD_LEADERBOARD_SUCCESS:
      return {
        ...state,
        ...payload.reduce((obj, win) => {
          const newObj = obj;
          newObj[win.id] = win;
          return obj;
        }, {}),
      };
    case ADD_WIN_SUCCESS:
      return {
        [payload.id]: payload,
        ...state,
      };
    case UPDATE_INITIALS_SUCCESS: {
      const win = state[payload.id];
      win.initials = payload.initials;
      const { [payload.id]: toDelete, ...theRest } = state;
      return {
        ...theRest,
        ...win,
      };
    }
    default:
      return state;
  }
};

const getSortedIds = (state = [], { type, payload }) => {
  switch (type) {
    case LOAD_LEADERBOARD_SUCCESS: {
      const sortedPayload = payload.sort((a, b) => a.elapsedTime > b.elapsedTime);
      return sortedPayload.map((win) => {
        const { id, elapsedTime } = win;
        return { id, elapsedTime };
      });
    }
    case ADD_WIN_SUCCESS: {
      const newWin = { id: payload.id, elapsedTime: payload.elapsedTime };
      return [newWin, ...state].sort((a, b) => a.elapsedTime - b.elapsedTime);
    }
    default:
      return state;
  }
};

const lastWinId = (state = null, { type, payload }) => {
  switch (type) {
    case ADD_WIN_SUCCESS:
      return payload.id;
    default:
      return state;
  }
};

const canAddInitials = (state = false, { type, payload }, sortedIds) => {
  switch (type) {
    case ADD_WIN_SUCCESS: {
      const index = sortedIds.findIndex(win => win.id === payload.id);
      return index < LEADERBOARD_COUNT;
    }
    case UPDATE_INITIALS_SUCCESS:
      return false;
    default:
      return state;
  }
};

export default function (state = {}, action) {
  const sortedIds = getSortedIds(state.sortedIds, action);
  return {
    byId: byId(state.byId, action),
    sortedIds,
    lastWinId: lastWinId(state.lastWinId, action),
    canAddInitials: canAddInitials(state.canAddInitials, action, sortedIds),
  };
}

export const getWin = (state, id) => state.byId[id];
export const getSortedWins = state => (
  state.sortedIds.slice(0, LEADERBOARD_COUNT).map(win => getWin(state, win.id))
);
