import * as types from '../actionTypes/cards';
import { winGame, startGame } from './game';
import { getCurrentLevel } from '../reducers/levels';

const flip = id => ({
  type: types.FLIP,
  payload: id,
  meta: {
    sound: {
      play: 'flips.flipCard',
    },
  },
});

const noMatch = () => ({
  type: types.NO_MATCH,
});

const clearFlipped = () => ({
  type: types.CLEAR_FLIPPED,
});

const match = newMatch => ({
  type: types.MATCH,
  payload: newMatch,
  meta: {
    sound: {
      play: 'match',
    },
  },
});

const wait = ms => new Promise(resolve => setTimeout(resolve, ms));

export const flipCard = cardId => async (dispatch, getState) => {
  const state = getState();
  const { cardsById, cardsToMatch, allCardIds } = getCurrentLevel(state.levels);
  const { flippedCardIds, matchedCardIds } = state.cards;
  const { allowFlips, playing, won } = state.game;

  if (allowFlips) {
    if (!playing && !won) {
      dispatch(startGame());
    }

    // only flip a card if we haven't reached the number of cards to match yet
    if (flippedCardIds.indexOf(cardId) < 0
      && matchedCardIds.indexOf(cardId) < 0
      && flippedCardIds.length < cardsToMatch) {
      dispatch(flip(cardId));

      // check to see if the new card matches the first card flipped, if not then no match
      if (flippedCardIds.length) {
        const newCard = cardsById[cardId];
        const firstCard = cardsById[flippedCardIds[0]];
        if (newCard.card !== firstCard.card) {
          // wait for a second so that the player can see the cards flipped
          dispatch(noMatch());
          await wait(1000);
          dispatch(clearFlipped());
        } else {
          const newlyFlipped = [...flippedCardIds, cardId];
          // if we are about to flip the last card to match, announce the match instead
          // include whether or not all cards have been matched so we can know if the game is won
          if (newlyFlipped.length === cardsToMatch) {
            dispatch(match(newlyFlipped));
            const allMatched = [...matchedCardIds, ...newlyFlipped].length === allCardIds.length;
            if (allMatched) {
              // if we won stop the timer and load the leaderboard
              const { baseTime, startedAt } = state.timer;
              const { selectedDifficulty } = state.levels;
              dispatch(winGame(selectedDifficulty, baseTime, startedAt));
            }
          }
        }
      }
    }
  }
};

export const changeCardBack = newBack => ({
  type: types.CHANGE_BACK,
  payload: newBack,
});
