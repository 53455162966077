import styled, { css } from 'styled-components';
import { media } from '../../mediaBreakpoints';
import CenteredFlex from './CenteredFlex';

export const SettingsButton = styled.button`
  border: 0;
  font-size: 3.5vw;
  background: transparent;
  color: black;
  margin: 0;
  padding: 0;
  ${media.desktop`
    font-size: 2vw;
  `}
  span {
    font-size: 1.4em;
  }
  ${props => props.darkMode && css`
    color: rgb(161, 161, 161);
  `};
`;

export const SettingsTitle = styled.h3`
  margin: 0 0 1em 0;
  font-size: 5vw;
  text-align: center;
  ${props => props.inline && css`
    margin-bottom: 0;
    margin-right: 1em;
  `}
  ${media.desktop`
    font-size: 2vw;
  `}
`;

export const StyledSettingsContainer = styled.div`
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  z-index: 1;
  background-image: linear-gradient(#eee, #fff);
  padding: 6vw;
  grid-template-columns: repeat(6, 1fr);

  ${media.desktop`
    grid-column: 1;
    padding: 4vw 6vw;
  `}

  ${props => props.darkMode && css`
    background-image: linear-gradient(#222, #000);
    color: rgb(161, 161, 161);
  `};
`;

export const ContinueGameButton = styled.button`
  font-size: 7vw;
  padding: 3vw 6vw;
  border: 0;
  background-color: #ccc;
  ${media.desktop`
    padding: 1.5vw 3vw;
    font-size: 3vw;
  `}
`;

export const ToggleContainer = styled(CenteredFlex)`
  flex-flow: wrap;
  h3 {
    flex-basis: 45%;
    text-align: left;
    margin-bottom: 0.5em;
    + div {
      margin-bottom: 0.5em;
    }
  }
`;
