import React from 'react';
import PropTypes from 'prop-types';
import { StyledCardBack } from './styled/CardBackOption';

const CardBackOption = ({
  option,
  onChangeBack,
  selectedOption,
  darkMode,
}) => (
  <StyledCardBack
    className={option}
    onClick={onChangeBack}
    selected={selectedOption === option}
    darkMode={darkMode}
  >
    <span>{option}</span>
  </StyledCardBack>
);

CardBackOption.propTypes = {
  option: PropTypes.string.isRequired,
  selectedOption: PropTypes.string.isRequired,
  onChangeBack: PropTypes.func.isRequired,
  darkMode: PropTypes.bool.isRequired,
};

export default CardBackOption;
