import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { changeCardBack as changeCardBackAction } from '../redux/actions/cards';
import CardBackOptionsList from '../components/CardBackOptionsList';
import CardBackOption from '../components/CardBackOption';

const cardBackOptions = [
  'dark',
  'rainbow',
  'blues',
  'sunny',
  'green',
  'indigo',
];

const CardBackOptionsContainer = ({ selectedBack, setCardBack, darkMode }) => (
  <CardBackOptionsList title="Change deck">
    {cardBackOptions.map(back => (
      <CardBackOption
        key={back}
        option={back}
        selectedOption={selectedBack}
        onChangeBack={() => setCardBack(back)}
        darkMode={darkMode}
      />
    ))}
  </CardBackOptionsList>
);

CardBackOptionsContainer.propTypes = {
  selectedBack: PropTypes.string.isRequired,
  setCardBack: PropTypes.func.isRequired,
  darkMode: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  selectedBack: state.cards.selectedBack,
  darkMode: state.game.darkMode,
});

export default connect(
  mapStateToProps,
  { setCardBack: changeCardBackAction },
)(CardBackOptionsContainer);
