import React from 'react';
import PropTypes from 'prop-types';
import { SettingsTitle } from './styled/Settings';
import CenteredFlex from './styled/CenteredFlex';

const LevelOptionsList = ({ title, children }) => (
  <div>
    <SettingsTitle>{title}</SettingsTitle>
    <CenteredFlex>{children}</CenteredFlex>
  </div>
);

LevelOptionsList.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default LevelOptionsList;
