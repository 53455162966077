import styled, { keyframes, css } from 'styled-components';
import { media } from '../../mediaBreakpoints';

const bgfade = keyframes`
  from {
    background: white;
  }

  to {
    background: orange;
  }
`;

const bgfadeDarkMode = keyframes`
  from {
    background: black;
  }

  to {
    background: rebeccapurple;
  }
`;

export const StyledLeaderboard = styled.div`
  font-family: monospace;
  background-color: #fff;
  ${props => props.darkMode && css`
    background-color: #000;
  `};
`;

export const LeaderHeader = styled.h2`
  text-align: center;
  text-transform: uppercase;
  font-size: 7vw;
  padding: 0.25em 0 0.5em 0;
  margin: 0;
  ${media.desktop`
    font-size: 2vw;
  `}
`;

export const LeaderTable = styled.table`
  margin: 0 auto;
  font-size: 6vw;
  border-collapse: collapse;
  width: 100%;
  thead {
    display: none;
  }
  ${media.tablet`
    width: auto;
  `}
  ${media.desktop`
    font-size: 2vw;
  `}
`;

export const LeaderTableRow = styled.tr`
  td {
    text-align: left;
    padding: 0.2em 1em 0.2em 1em;
    text-transform: uppercase;
    &:first-child {
      font-size: 1.2em;
      padding-right: 0;
    }
    ${props => props.highlight && css`
      animation: ${bgfade} 1s ease-in infinite;
      animation-direction: alternate;

      ${props.darkMode && css`
        animation-name: ${bgfadeDarkMode};
      `};
    `};
  }
`;
