const REDUX_STATE = 'redux-state';

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem(REDUX_STATE);
    if (serializedState == null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    // do not store the leaderboard in localStorage
    const { leaderboard, ...filteredState } = state;
    const serializedState = JSON.stringify(filteredState);
    localStorage.setItem(REDUX_STATE, serializedState);
  } catch (err) {
    // ignore
  }
};
