import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from '../../mediaBreakpoints';

export const formatTime = (time) => {
  if (time < 0) return '--:--';
  const h = Math.floor(time / 3600);
  const m = Math.floor((time % 3600) / 60);
  const mm = m < 10 ? `0${m}` : m;
  const s = time % 60;
  const ms = s % 1 !== 0 ? s.toFixed(3) : s;
  const ss = s < 10 ? `0${ms}` : ms;
  if (h > 0) return [h, mm, ss].join(':');
  return `${m}:${ss}`;
};

const StyledTimer = styled.span`
  font-family: monospace;
  font-size: 6.5vw;
  ${media.desktop`
    font-size: 3.5vw;
  `}
`;

const Timer = ({ time = 0 }) => <StyledTimer>{formatTime(time)}</StyledTimer>;

Timer.propTypes = {
  time: PropTypes.number.isRequired,
};

export const getElapsedTime = (baseTime, startedAt, stoppedAt) => {
  if (!startedAt) {
    return 0;
  }
  return stoppedAt - startedAt + baseTime;
};

const StyledTimerContainer = styled.div`
  text-align: center;
`;

class TimerContainer extends React.Component {
  componentDidMount() {
    this.interval = setInterval(this.forceUpdate.bind(this), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { baseTime, startedAt, stoppedAt } = this.props;
    const elapsed = getElapsedTime(baseTime, startedAt, stoppedAt || new Date().getTime());
    const elapsedSeconds = Math.floor(elapsed / 1000);

    return (
      <StyledTimerContainer>
        <Timer time={elapsedSeconds} />
      </StyledTimerContainer>
    );
  }
}

TimerContainer.propTypes = {
  baseTime: PropTypes.number.isRequired,
  startedAt: PropTypes.number.isRequired,
  stoppedAt: PropTypes.number,
};

TimerContainer.defaultProps = {
  stoppedAt: null,
};

export default TimerContainer;
