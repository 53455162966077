import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LEADERBOARD_COUNT } from '../redux/reducers/leaderboard';
import { SkipLink } from './styled/App';

class App extends Component {
  componentWillMount() {
    const {
      loadLevels,
      difficulty,
      soundEffects,
      loadLeaderboard,
    } = this.props;

    loadLevels();
    loadLeaderboard(difficulty, LEADERBOARD_COUNT);

    if (window && window.Howler) {
      window.Howler.mute(!soundEffects);
    }
  }

  render() {
    const { darkMode, children } = this.props;
    if (darkMode) {
      document.body.style.backgroundColor = '#000000';
    } else {
      document.body.style.backgroundColor = '#ffffff';
    }
    return (
      <React.Fragment>
        <SkipLink href="#main">Skip to main content</SkipLink>
        {children}
      </React.Fragment>
    );
  }
}

App.propTypes = {
  loadLevels: PropTypes.func.isRequired,
  loadLeaderboard: PropTypes.func.isRequired,
  difficulty: PropTypes.string.isRequired,
  soundEffects: PropTypes.bool.isRequired,
  darkMode: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default App;
