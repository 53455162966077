import styled from 'styled-components';
import { media } from '../../mediaBreakpoints';

export default styled.p`
  text-align: center;
  font-family: monospace;
  font-size: 7vw;
  font-weight: bold;
  margin-top: 0;
  span {
    text-transform: uppercase;
    font-weight: normal;
  }
  ${media.desktop`
    font-size: 2.5vw;
  `}
`;
