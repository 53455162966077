import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { pauseGame as pauseGameAction } from '../redux/actions/game';
import Timer from '../components/Timer/Timer';
import { Header, AppTitle } from '../components/styled/Header';
import { SettingsButton } from '../components/styled/Settings';

const HeaderContainer = ({
  darkMode,
  playing,
  paused,
  won,
  pauseGame,
  baseTime,
  startedAt,
  stoppedAt,
}) => (
  <Header darkMode={darkMode}>
    <AppTitle>Memory</AppTitle>
    {playing && <Timer baseTime={baseTime} startedAt={startedAt} stoppedAt={stoppedAt} />}
    <div>
      {!paused && !won && (
        <SettingsButton type="button" onClick={() => pauseGame()} darkMode={darkMode}>
          <span>⚙</span> Settings
        </SettingsButton>
      )}
    </div>
  </Header>
);

HeaderContainer.propTypes = {
  darkMode: PropTypes.bool.isRequired,
  playing: PropTypes.bool.isRequired,
  paused: PropTypes.bool.isRequired,
  won: PropTypes.bool.isRequired,
  pauseGame: PropTypes.func.isRequired,
  baseTime: PropTypes.number.isRequired,
  startedAt: PropTypes.number,
  stoppedAt: PropTypes.number,
};

HeaderContainer.defaultProps = {
  startedAt: null,
  stoppedAt: null,
};

const mapStateToProps = state => ({
  darkMode: state.game.darkMode,
  playing: state.game.playing,
  paused: state.game.paused,
  won: state.game.won,
  baseTime: state.timer.baseTime,
  startedAt: state.timer.startedAt,
  stoppedAt: state.timer.stoppedAt,
});

export default connect(
  mapStateToProps,
  { pauseGame: pauseGameAction },
)(HeaderContainer);
