import styled, { css } from 'styled-components';
import { media } from '../../mediaBreakpoints';

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3vw 6vw;
  background-color: #fff;
  border-bottom: solid 0.5vw #ccc;
  ${media.desktop`
    padding: 1.5vw 6vw;
  `}
  ${props => props.darkMode && css`
    background-color: #000;
    color: rgb(161, 161, 161);
  `};
`;

export const AppTitle = styled.h1`
  margin: 0;
  font-size: 6.5vw;
  text-transform: uppercase;
  ${media.desktop`
    font-size: 3.5vw;
  `}
`;
