import styled from 'styled-components';
import { media } from '../../mediaBreakpoints';

export const PlayingArea = styled.div`
  position: relative;
  display: grid;
  ${media.desktop`
    grid-template-columns: 1fr 1fr;
  `}
`;

export const SkipLink = styled.a`
  position: absolute;
  left: -9999px;
  &:focus {
    left: 0;
  }
`;
