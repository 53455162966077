import styled from 'styled-components';
import { media } from '../../mediaBreakpoints';

export default styled.button`
  background-color: #ddd;
  border-radius: 5px;
  border: solid 0.3vw #aaa;
  text-transform: capitalize;
  padding: 0.5em 1em;
  display: block;
  margin-bottom: 1em;
  margin-right: 1em;
  font-size: 5vw;
  ${media.desktop`
    font-size: 2vw;
  `}
`;
