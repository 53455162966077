import React from 'react';
import PropTypes from 'prop-types';
import LevelOptionButton from './styled/LevelOptionButton';

const LevelOption = ({ option, onSetDifficulty }) => (
  <LevelOptionButton type="button" onClick={onSetDifficulty}>
    {option}
  </LevelOptionButton>
);

LevelOption.propTypes = {
  option: PropTypes.string.isRequired,
  onSetDifficulty: PropTypes.func.isRequired,
};

export default LevelOption;
