import Dexie from 'dexie';

if (process.env.NODE_ENV === 'test') {
  Dexie.dependencies.indexedDB = require('fake-indexeddb'); // eslint-disable-line global-require
  Dexie.dependencies.IDBKeyRange = require('fake-indexeddb/lib/FDBKeyRange'); // eslint-disable-line global-require
}

const db = new Dexie('Memory');
db.version(1).stores({
  wins: '++id, elapsedTime, initials, level, createdOn',
});

export default db;
