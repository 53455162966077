import * as types from '../actionTypes/game';
import { startTimer, resetTimer, stopTimer } from './timer';
import { getElapsedTime } from '../../components/Timer/Timer';
import { addWin } from './leaderboard';

const start = () => ({
  type: types.START,
});

export const startGame = () => (dispatch) => {
  dispatch(startTimer(0));
  dispatch(start());
};

export const reset = cardIdOrder => ({
  type: types.RESET,
  payload: cardIdOrder,
});

export const resetGame = cardIdOrder => (dispatch) => {
  dispatch(resetTimer());
  dispatch(reset(cardIdOrder));
};

const pause = () => ({
  type: types.PAUSE,
});

export const pauseGame = () => (dispatch) => {
  dispatch(stopTimer(new Date().getTime()));
  dispatch(pause());
};

const resume = () => ({
  type: types.RESUME,
});

export const resumeGame = basetime => (dispatch) => {
  dispatch(startTimer(basetime));
  dispatch(resume());
};

const win = () => ({
  type: types.WIN,
  meta: {
    sound: {
      play: 'won',
    },
  },
});

export const winGame = (level, baseTime, startedAt) => async (dispatch) => {
  const stoppedAt = new Date().getTime();
  dispatch(stopTimer(stoppedAt));
  const elapsedTime = getElapsedTime(baseTime, startedAt, stoppedAt);
  const newWin = { level, elapsedTime };
  dispatch(addWin(newWin));
  dispatch(win());
};

export const toggleSound = () => ({
  type: types.TOGGLE_SOUND,
});

export const toggleDarkMode = () => ({
  type: types.TOGGLE_DARK_MODE,
});
