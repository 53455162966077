import { combineReducers } from 'redux';
import { START, STOP, RESET } from '../actionTypes/timer';

const baseTime = (state = null, { type, payload }) => {
  switch (type) {
    case START:
      return payload.basetime;
    case RESET:
      return 0;
    default:
      return state;
  }
};

const startedAt = (state = null, { type, payload }) => {
  switch (type) {
    case START:
      return payload.now;
    case RESET:
      return null;
    default:
      return state;
  }
};

const stoppedAt = (state = null, { type, payload }) => {
  switch (type) {
    case STOP:
      return payload;
    case RESET:
    case START:
      return null;
    default:
      return state;
  }
};

export default combineReducers({
  baseTime,
  startedAt,
  stoppedAt,
});
