import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Switch from 'react-toggle-switch';
import { toggleSound as toggleSoundAction } from '../redux/actions/game';
import { SettingsTitle } from '../components/styled/Settings';

const SoundEffectsContainer = ({ soundEffects, toggleSound }) => (
  <React.Fragment>
    <SettingsTitle inline>Sound effects</SettingsTitle>
    <Switch
      onClick={() => {
        toggleSound();
        if (window && window.Howler) {
          // soundEffects will not have been toggled yet so we need to do it ourselves
          window.Howler.mute(soundEffects);
        }
      }}
      on={soundEffects}
    />
  </React.Fragment>
);

SoundEffectsContainer.propTypes = {
  soundEffects: PropTypes.bool.isRequired,
  toggleSound: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  soundEffects: state.game.soundEffects,
});

export default connect(
  mapStateToProps,
  { toggleSound: toggleSoundAction },
)(SoundEffectsContainer);
