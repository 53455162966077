import styled, { css } from 'styled-components';
import { media } from '../../mediaBreakpoints';

export default styled.div`
  border-bottom: solid 0.5vw #ccc;
  padding-bottom: 9vw;
  margin-bottom: 9vw;
  text-align: center;
  ${media.desktop`
    padding-bottom: 4.5vw;
    margin-top: 0;
    margin-bottom: 4.5vw;
  `}
  ${props => props.noborder && css`
    padding-bottom: 0;
    border-bottom: 0;
    ${media.desktop`
      padding-bottom: 0;
      border-bottom: 0;
    `}
  `}
`;
