import fetch from 'cross-fetch';
import * as types from '../actionTypes/levels';

const loadLevelsRequest = () => ({
  type: types.LOAD_LEVELS_REQUEST,
});

const loadLevelsSuccess = levels => ({
  type: types.LOAD_LEVELS_SUCCESS,
  payload: levels,
});

const loadLevelsFailure = error => ({
  type: types.LOAD_LEVELS_FAILURE,
  error: true,
  payload: error,
});

// eslint-disable-next-line import/prefer-default-export
export const loadLevels = () => async (dispatch) => {
  dispatch(loadLevelsRequest());

  try {
    const response = await fetch('https://raw.githubusercontent.com/ebello/ern.me/master/cards.json');
    const cardData = await response.json();
    dispatch(loadLevelsSuccess(cardData));
  } catch (error) {
    dispatch(loadLevelsFailure(error));
  }
};

export const setDifficulty = difficulty => ({
  type: types.SET_DIFFICULTY,
  payload: difficulty,
});
