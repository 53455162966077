import styled, { css } from 'styled-components';
import { media } from '../../mediaBreakpoints';

export default styled.div`
  padding-bottom: 140%;
  height: 0;
  position: relative;
  transition: all .5s ease;
  transform-style: preserve-3d;
  outline: none;
  &:before {
    content: '';
    border-radius: 4vw;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    backface-visibility: hidden;
    border: solid 1px transparent;
    ${media.desktop`
      border-radius: 2vw;
    `}
  }
  &:focus {
    &:before {
      border-color: black;
      ${props => props.darkMode && css`
        border-color: #aaa;
      `};
    }
  }
  &.rainbow {
    &:before {
      background-image: linear-gradient(to right, #eea2a2 0%, #bbc1bf 19%, #57c6e1 42%, #b49fda 79%, #7ac5d8 100%);
    }
  }
  &.dark {
    &:before {
      background: linear-gradient(to bottom, rgba(255,255,255,0.15) 0%, rgba(0,0,0,0.15) 100%), radial-gradient(at top center, rgba(255,255,255,0.40) 0%, rgba(0,0,0,0.40) 120%) #989898;
      background-blend-mode: multiply,multiply;
    }
  }
  &.blues {
    &:before {
      background-image: linear-gradient(to top, #00c6fb 0%, #005bea 100%);
    }
  }
  &.sunny {
    &:before {
      background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
    }
  }
  &.green {
    &:before {
      background-image: linear-gradient(-60deg, #16a085 0%, #f4d03f 100%);
    }
  }
  &.indigo {
    &:before {
      background-image: radial-gradient(circle at bottom center, #b224ef 15px, #7579ff);
    }
  }
  ${props => props.flipped && css`
    transform: rotateY(180deg);
  `};
  > span {
    border: solid 1px #000;
    background-color: #fff;
    border-radius: 4vw;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: rotateY(180deg);
    backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16vw;
    line-height: 1;
    ${media.desktop`
      font-size: 8vw;
      border-radius: 2vw;
    `}
    ${props => props.darkMode && css`
      background-color: #000;
      color: #fff;
      border-color: #fff;
    `};
  }
`;
