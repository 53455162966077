import { css } from 'styled-components';

const breakpoints = {
  mobilePlus: 414,
  tablet: 768,
  desktop: 1024,
};

const media = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${breakpoints[label] / 16}em) {
      ${css(...args)}
    }
  `;

  return acc;
}, {});

const mediaMax = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${(breakpoints[label] - 1) / 16}em) {
      ${css(...args)}
    }
  `;

  return acc;
}, {});

export { media, mediaMax };
