import styled, { css } from 'styled-components';
import StyledCard from './StyledCard';
import { media } from '../../mediaBreakpoints';

export const StyledCardBack = styled(StyledCard)`
  padding-bottom: 20%;
  &:before {
    border: solid 1vw #fff;
    ${media.desktop`
      border-width: 0.5vw;
    `}
    ${props => props.darkMode && css`
      border-color: #000;
    `};
  }
  ${props => props.selected && css`
    &:before {
      border-color: orange;
      ${props.darkMode && css`
        border-color: rebeccapurple;
      `};
    }
  `};
`;

export const OptionsContainer = styled.div`
  display: flex;
  justify-content: center;
  > * {
    flex-basis: ${({ children }) => ((100 / children.length) - 3)}%;
  }
`;

export const CardBackOptionsListContainer = styled.div`
  margin-bottom: 9vw;
  ${media.desktop`
    margin-bottom: 5vw;
  `}
`;
