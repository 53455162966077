import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SettingsTitle } from './styled/Settings';
import { HighScoreFormContainer, StyledParagraph } from './styled/HighScoreForm';

class HighScoreForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initials: 'AAA',
    };
  }

  componentDidMount() {
    const { initials } = this.props;
    if (initials) {
      this.setState({ initials });
    }

    this.initials.focus();
  }

  handleInputChange = (ev) => {
    const { target } = ev;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  }

  render() {
    const { id, saveInitials } = this.props;
    const { initials } = this.state;

    return (
      <HighScoreFormContainer>
        <SettingsTitle>You got a high score!</SettingsTitle>
        <StyledParagraph>Enter your initials</StyledParagraph>
        <form
          onSubmit={(ev) => {
            ev.preventDefault();
            if (initials) {
              saveInitials(id, initials);
            }
          }}
        >
          <ol>
            <li>
              <input
                type="text"
                name="initials"
                ref={(input) => { this.initials = input; }}
                onChange={this.handleInputChange}
                value={initials}
                maxLength="3"
                minLength="3"
              />
            </li>
            <li><button type="submit">Save</button></li>
          </ol>
        </form>
      </HighScoreFormContainer>
    );
  }
}

HighScoreForm.propTypes = {
  id: PropTypes.number.isRequired,
  initials: PropTypes.string,
  saveInitials: PropTypes.func.isRequired,
};

HighScoreForm.defaultProps = {
  initials: 'AAA',
};

export default HighScoreForm;
