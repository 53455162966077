import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Switch from 'react-toggle-switch';
import { toggleDarkMode as toggleDarkModeAction } from '../redux/actions/game';
import { SettingsTitle } from '../components/styled/Settings';

const DarkModeContainer = ({ darkMode, toggleDarkMode }) => (
  <React.Fragment>
    <SettingsTitle inline>Dark mode</SettingsTitle>
    <Switch onClick={() => toggleDarkMode()} on={darkMode} />
  </React.Fragment>
);

DarkModeContainer.propTypes = {
  darkMode: PropTypes.bool.isRequired,
  toggleDarkMode: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  darkMode: state.game.darkMode,
});

export default connect(
  mapStateToProps,
  { toggleDarkMode: toggleDarkModeAction },
)(DarkModeContainer);
