import { combineReducers } from 'redux';
import { START, RESET } from '../actionTypes/game';
import {
  FLIP,
  MATCH,
  CLEAR_FLIPPED,
  CHANGE_BACK,
} from '../actionTypes/cards';

// get unique values from array combination
const addToArray = (state, payload) => [...new Set([...state, ...payload])];

const flippedCardIds = (state = [], { type, payload }) => {
  switch (type) {
    case FLIP:
      return addToArray(state, [payload]);
    case START:
    case RESET:
    case CLEAR_FLIPPED:
    case MATCH:
      return [];
    default:
      return state;
  }
};

const matchedCardIds = (state = [], { type, payload }) => {
  switch (type) {
    case MATCH:
      return addToArray(state, payload);
    case START:
    case RESET:
      return [];
    default:
      return state;
  }
};

const selectedBack = (state = 'dark', { type, payload }) => {
  switch (type) {
    case CHANGE_BACK:
      return payload;
    default:
      return state;
  }
};

export default combineReducers({
  flippedCardIds,
  matchedCardIds,
  selectedBack,
});
