import { connect } from 'react-redux';
import { flipCard as flipCardAction } from '../redux/actions/cards';
import { getCurrentLevel } from '../redux/reducers/levels';
import Game from '../components/Game';

const mapStateToProps = state => ({
  won: state.game.won,
  playing: state.game.playing,
  paused: state.game.paused,
  darkMode: state.game.darkMode,
  deck: getCurrentLevel(state.levels).cardsById,
  cardIds: state.game.cardIdOrder,
  flippedCardIds: state.cards.flippedCardIds,
  matchedCardIds: state.cards.matchedCardIds,
  cardDesign: state.cards.selectedBack,
});

export default connect(
  mapStateToProps,
  { flipCard: flipCardAction },
)(Game);
