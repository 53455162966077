import styled from 'styled-components';
import { media } from '../../mediaBreakpoints';

export const HighScoreFormContainer = styled.div`
  text-align: center;
  ol {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  li {
    margin-bottom: 1em;
  }
  input {
    text-transform: uppercase;
    font-family: monospace;
    width: 12vw;
    font-size: 6vw;
    ${media.desktop`
      font-size: 3vw;
      width: 6vw;
    `}
  }
  button {
    font-size: 5vw;
    ${media.desktop`
      font-size: 2vw;
    `}
  }
`;

export const StyledParagraph = styled.p`
  font-size: 4vw;
  margin-top: 0;
  ${media.desktop`
    font-size: 1.5vw;
  `}
`;
