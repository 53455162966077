import { combineReducers } from 'redux';
import { LOAD_LEVELS_SUCCESS, SET_DIFFICULTY } from '../actionTypes/levels';

const cardsById = cards => cards.reduce((obj, card) => {
  const newObj = obj;
  newObj[card.id] = card;
  return obj;
}, {});

const byDifficulty = (state = {}, { type, payload }) => {
  switch (type) {
    case LOAD_LEVELS_SUCCESS:
      return {
        ...state,
        ...payload.reduce((obj, lvl) => {
          const newObj = obj;
          const cards = lvl.cards
            .map((card, index) => Object.assign({}, { id: index + 1, card }));

          newObj[lvl.difficulty] = {
            allCardIds: cards.map(card => card.id),
            cardsById: cardsById(cards),
            cardsToMatch: lvl.cardsToMatch,
          };
          return obj;
        }, {}),
      };
    default:
      return state;
  }
};

const selectedDifficulty = (state = 'easy', { type, payload }) => {
  switch (type) {
    case SET_DIFFICULTY:
      return payload;
    default:
      return state;
  }
};

export default combineReducers({
  byDifficulty,
  selectedDifficulty,
});

export const getCurrentLevel = state => state.byDifficulty[state.selectedDifficulty] || {};
