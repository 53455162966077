import styled, { css } from 'styled-components';
import { mediaMax, media } from '../../mediaBreakpoints';

export default styled.div`
  padding: 6vw;
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  z-index: 0;
  display: grid;
  grid-gap: 4vw;
  grid-template-columns: repeat(4, 1fr);
  perspective: 100vmax;
  perspective-origin: center center;
  transform-style: preserve-3d;
  align-content: flex-start;
  ${mediaMax.tablet`
    ${props => props.won && css`
      display: none;
    `}
  `}
  ${media.desktop`
    grid-gap: 2vw;
    padding: 4vw 6vw;
    ${props => (!props.won && !props.paused) && css`
      grid-template-columns: repeat(8, 1fr);
    `}

    ${props => (props.won || props.paused) && css`
      grid-column: 2;
    `}
  `}
`;
